/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue/dist/vue.esm'
import Vuelidate from 'vuelidate'
import httpPlugin from './plugins/http'
import ContactButton from './contactButton'
import ContactOffer from './contactOffer'
import ContactModal from './contactModal'
import NavBar from './navbar'

Vue.use(httpPlugin)
Vue.use(Vuelidate)

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',

    components: {
      ContactButton,
      ContactOffer,
      ContactModal,
      NavBar
    },

    methods: {
      openModal() {
        this.$refs.contactModal.openModal()
      }
    }
  })
})
