<template>
  <div class="contact-offer">
    <section class="call-offer">
      <p class="description">
        Sua casa merece uma limpeza realmente eficaz, contra ácaros
        Solicite um orçamento agora mesmo! 
      </p>
      
      <a :href="whatsapp_url" target="_blank">
        <span>ORÇAMENTO POR WHATSAPP <strong>(55) 99923-5285</strong></span>
      </a>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'contact-offer',

    data() {
      return {
        whatsapp_url: 'http://api.whatsapp.com/send?1=pt_BR&phone=55999235285&text=Ol%C3%A1%20Gostaria%20de%20um%20Or%C3%A7amento!%20'
      }
    },

    methods: {
      openModal() {
        this.$emit('open-modal')
      },
    }
  }
</script>
