<template>
  <div class="contact">
    <a class="button is-hidden-tablet" :href="whatsapp_url" target="_blank">
      <span>PEDIR UM ORÇAMENTO</span>
      <svg class="icon" fill="#FFFFFF" viewBox="0 0 90 112.5" xmlns="http://www.w3.org/2000/svg">
        <path  d="M47.3,18c-1.4,1.4-1.4,3.7,0,4.9l18.8,18.6H13c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5h53.1L47.3,67.1  c-1.4,1.4-1.4,3.5,0,4.9c1.4,1.4,3.5,1.4,4.9,0l27.3-27L52,18C50.8,16.6,48.5,16.6,47.3,18z"/>
      </svg>
    </a>

    <a class="button is-hidden-mobile" :href="whatsapp_url" target="_blank">
      <span>ORÇAMENTO POR WHATSAPP <strong>(55) 99923-5285</strong></span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'contact-button',

    data() {
      return {
        whatsapp_url: 'http://api.whatsapp.com/send?1=pt_BR&phone=55999235285&text=Olá!%20Gostaria%20orçamento'
      }
    },

    methods: {
      openModal() {
        this.$emit('open-modal')
      },
    }
  }
</script>
