import axios from 'axios'

const getToken = () =>
  document.querySelector('[name="csrf-token"]').getAttribute('content')

const getUrl = () =>
  window.location.href

// allow use http client without Vue instance
export const http = axios.create({
  baseURL: getUrl(),
  headers: {
    'X-CSRF-Token': getToken(),
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

// receive store and data by options
// https://vuejs.org/v2/guide/plugins.html
export default function install(Vue) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return http
    },
  })
}
