<template lang="html">
  <div class="modal" :class="classModal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">
          <h2>{{ title }}</h2>
          <p class="modal-card-subitle">{{ subtitle }}</p>
        </div>
        <button @click="onClose" class="delete"></button>
      </header>

      <section class="modal-card-body">
        <slot></slot>
      </section>

      <footer class="modal-card-foot">
        <a
          class="button is-primary is-medium"
          :class="{'is-loading': this.isFetching }"
          @click="onSave">
          Enviar
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'modal',

    props: {
      title: {
        type: String,
        required: true
      },

      subtitle: {
        type: String,
        required: true
      },

      isVisible: {
        type: Boolean,
        required: true,
      },

      isFetching: {
        type: Boolean,
        default: false
      },

      closeModal: {
        type: Function,
        required: true
      },

      saveButton: {
        type: Function,
        required: true
      }
    },

    computed: {
      classModal: function () {
        return {
          "is-active": this.isVisible
        }
      }
    },

    mounted: function () {
      document.addEventListener("keydown", (e) => {
        if (this.isVisible && e.keyCode == 27) {
          this.closeModal()
        }
      })
    },

    methods: {
      onSave() {
        this.saveButton()
      },

      onClose() {
        this.closeModal()
      }
    }
  }
</script>