<template lang="html">
  <div class="contact_modal">
    <modal
      :title="title"
      :subtitle="subtitle"
      :isVisible="isVisible"
      :isFetching="isFetching"
      :closeModal="closeModal"
      :saveButton="sendEmail">

      <form>
        <div class="field">
          <label class="label" for="contact_name">Nome</label>
          <p class="control">
            <input
              id="contact_name"
              class="input is-medium"
              :class="{'is-danger': $v.contact.name.$error }"
              type="text"
              autofocus="true"
              :disabled="isFetching"
              v-model="contact.name">
          </p>
        </div>

        <div class="field">
          <label class="label" for="contact_email">Email</label>
          <p class="control">
            <input
              id="contact_email"
              class="input is-medium"
              :class="{'is-danger': $v.contact.email.$error }"
              type="text"
              :disabled="isFetching"
              v-model="contact.email">
          </p>
        </div>


        <div class="field">
          <label class="label" for="contact_phone">Telefone</label>
          <p class="control">
            <input
              id="contact_phone"
              class="input is-medium"
              :class="{'is-danger': $v.contact.phone.$error }"
              type="text"
              :disabled="isFetching"
              v-model="contact.phone">
          </p>
        </div>

        <div class="field">
          <label class="label" for="contact_message">Mensagem</label>
          <p class="control">
            <textarea
              id="contact_message"
              class="textarea"
              :class="{'is-danger': $v.contact.message.$error }"
              :disabled="isFetching"
              v-model="contact.message"></textarea>
          </p>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { http } from './plugins/http'
  import Modal from './modal'

  export default {
    name: 'contact-modal',

    data: function () {
      return {
        isVisible: false,
        isFetching: false,
        title: 'Solicitar Orçamento',
        subtitle: 'Preencha o formulário abaixo',
        contact: {
          name: '',
          email: '',
          phone: '',
          message: ''
        }
      }
    },

    validations: {
      contact: {
        name: { required },
        email: { required },
        phone: { required },
        message: { required }
      }
    },

    components: {
      Modal
    },

    methods: {
      sendEmail() {
        if (this.$v.contact.$invalid) {
          this.$v.contact.$touch()
        } else {
          this.isFetching = true
          const { contact } = this
          http.post('/contacts', { contact: contact })
          .then(data => {
            this.isFetching = false
            this.closeModal()
          })
          .catch(() => {
            this.isFetching = false
            console.warn('FAIL_IN_SEND_EMAIL')
          })
        }
      },

      openModal() {
        this.isVisible = true
      },

      closeModal() {
        this.isVisible = false
      },
    }
  }
</script>